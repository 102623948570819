import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PropTypes from "prop-types"

import "../css/boardPage.css"

export default class board extends Component {
  propTypes = {
    data: PropTypes.string.isRequired,
  }
  render() {
    let data = this.props.pageContext
    let finalArr = [
      {
        class: "Class 1",
        link: "class1",
        description: data.field_class_1.processed,
      },
      {
        class: "Class 2",
        link: "class2",
        description: data.field_class_2.processed,
      },
      {
        class: "Class 3",
        link: "class3",
        description: data.field_class_3.processed,
      },
      {
        class: "Class 4",
        link: "class4",
        description: data.field_class_4.processed,
      },
      {
        class: "Class 5",
        link: "class5",
        description: data.field_class_5.processed,
      },
      {
        class: "Class 6",
        link: "class6",
        description: data.field_class_6.processed,
      },
      {
        class: "Class 7",
        link: "class7",
        description: data.field_class_7.processed,
      },
      {
        class: "Class 8",
        link: "class8",
        description: data.field_class_8.processed,
      },
    ]

    if (data.field_class_9) {
      finalArr.push({
        class: "Class 9",
        link: "class9",
        description: data.field_class_9.processed,
      })
    }
    if (data.field_class_10) {
      finalArr.push({
        class: "Class 10",
        link: "class10",
        description: data.field_class_10.processed,
      })
    }
    if (data.field_class_11) {
      finalArr.push({
        class: "Class 11",
        link: "class11",
        description: data.field_class_11.processed,
      })
    }
    if (data.field_class_12) {
      finalArr.push({
        class: "Class 12",
        link: "class12",
        description: data.field_class_12.processed,
      })
    }

    return (
      <Layout>
        <SEO
          title={data.field_meta_title}
          description={data.field_meta_desc}
          lang="en"
        />
        <div>
          <div className="pageBanner paymentPage bannerText">
            <h1 className="displayNone">Boards</h1>
            <h2 className="banner_text_new"> Boards </h2>
          </div>
        </div>

        <div className="photo_video_heading text-center">
          <p>
            <span className="heading-blue">{data.field_board_name}</span>
          </p>
        </div>

        <div className="container first-content font-lato">
          <span
            dangerouslySetInnerHTML={{
              __html: data.field_content_intro.processed,
            }}
          />
        </div>
        <br />
        <div className="photo_video_heading text-center">
          <p>
            {" "}
            {data.field_board_name}&nbsp;
            <span className="heading-blue">Syllabus</span>
          </p>
        </div>

        <div className="container">
          <div className="row board-row">
            {finalArr.map((item, i) => {
              return (
                <div
                  className="board-card col-sm-12 col-md-4 col-xl-2 my-4"
                  key={i}
                >
                  <Link
                    to={"/board/" + data.field_board_name + "/" + item.link}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div className="my-3 mx-1">
                      <p className="board-card-heading font-samsung">
                        {item.class}&nbsp; {data.field_board_name}&nbsp;
                        Syllabus
                      </p>
                      <span
                        className="class-desc font-lato"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>

        <div className="container later-content font-lato">
          {" "}
          <span
            dangerouslySetInnerHTML={{
              __html: data.field_content_end.processed,
            }}
          />
        </div>
      </Layout>
    )
  }
}
